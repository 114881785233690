import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Main from '../components/main/main';

const Test = () => {
  const [permission, setPermission] = useState(false);
  const [orientation, setOrientation] = useState({
    alpha: 0,
    beta: 90,
    gamma: 0,
  });

  useEffect(() => {
    console.log(orientation);
  }, [orientation]);

  const handleOrientation = (event) => {
    const newOrientation = {
      alpha: Math.round(event.alpha),
      beta: Math.round(event.beta),
      gamma: Math.round(event.gamma),
    };
    setOrientation(newOrientation);
  };

  const getOrientationPermission = () => {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then((state) => {
          if (state === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation);
            setPermission(true);
          } else {
            setPermission(false);
          }
        })
        .catch(console.error);
    } else {
      window.addEventListener('deviceorientation', handleOrientation);
      setPermission(true);
    }
  };

  if (permission === false) {
    return (
      <Main center>
        <button onClick={getOrientationPermission}>Start</button>
      </Main>
    );
  } else {
    return (
      <div
        id={styles.test}
        style={{ transform: `rotate(${orientation.gamma}deg)` }}
      />
    );
  }
};

export default Test;
