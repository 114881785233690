import React from 'react';
import styles from './user-list.module.scss';
import PropTypes from 'prop-types';
import { MdClose, MdCheck } from 'react-icons/md';
import Heading from '../heading/heading';
import classNames from 'classnames';

const UserList = ({ users }) => {
  const userList = users.map((user) => {
    if (user.name) {
      return (
        <div key={user.id} className={styles.user}>
          <div
            className={classNames(styles.permission, {
              [styles.allowed]: user.permission === true,
            })}
          >
            {user.permission === true ? <MdCheck /> : <MdClose />}
          </div>
          <div className={styles.name}> {user.name}</div>
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <div className={styles.users}>
      <Heading className={styles.heading}>
        {userList.length} {userList.length === 1 ? 'user' : 'users'} in room:
      </Heading>
      <div className={styles.userList}>{userList}</div>
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
};

UserList.defaultProps = {
  users: [],
};

export default UserList;
