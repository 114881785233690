import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAJqs-ZBcJrFpajTcoUIYXCYkDBRD_c0ac",
  authDomain: "high-no.firebaseapp.com",
  databaseURL: "https://high-no-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "high-no",
  storageBucket: "high-no.appspot.com",
  messagingSenderId: "467202489074",
  appId: "1:467202489074:web:e1a4db5e9408bfcee56e6b",
  measurementId: "G-WD5JDW66QZ",
};

const firebaseApp = initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);
export const auth = getAuth();

auth.languageCode = "nb";
