import React from 'react';
import styles from './credits.module.scss';

const Credits = () => {
  return (
    <footer className={styles.credits}>
      Made by{' '}
      <a
        href="https://www.linkedin.com/in/alexander-solbakken/"
        target={'_blank'}
        rel={'noreferrer'}
      >
        Alexander Solbakken
      </a>{' '}
      {'&'} Magnus Snickars @ APT
    </footer>
  );
};

export default Credits;
