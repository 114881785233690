import React, { useContext, useRef, useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { ref, set, onValue, serverTimestamp } from 'firebase/database';
import { database } from '../utils/firebase';
import { SettingsContext } from '../contexts/settings-provider';
import playSound from '../utils/playSound';

import idleMusicSrc from '../assets/sound/music/1.mp3';
import playMusicSrc from '../assets/sound/music/2.mp3';
import aftermathMusicSrc from '../assets/sound/music/3.mp3';

import Main from '../components/main/main';
import Header from '../components/header/header';
import StageIntro from '../components/stage-intro/stage-intro';
import StageAftermath from '../components/stage-aftermath/stage-aftermath';
import StageHolster from '../components/stage-holster/stage-holster';
import StagePlay from '../components/stage-play/stage-play';
import Stage from '../components/stage/stage';
import Credits from '../components/credits/credits';
import Footer from '../components/footer/footer';
import UserList from '../components/user-list/user-list';

import background from '../assets/backgrounds/display.png';
import holdImage from '../assets/backgrounds/staredown.png';
import shootImage from '../assets/backgrounds/fire.png';

const Display = () => {
  const getRandomTime = () => {
    const minTime = 5;
    const maxExtra = 10;
    const now = Date.now();
    const delay = Math.floor(Math.random() * maxExtra + minTime) * 1000;
    return { now: now, delay: delay, time: now + delay };
  };

  const musicRef = useRef(null);
  const [settings] = useContext(SettingsContext);

  const location = useLocation();
  const [validRoom, setValidRoom] = useState(null);
  const [roomCode] = useState(location.state?.room);
  const [userUid] = useState(settings.user.uid);

  const [gameStage, setGameStage] = useState('intro');
  const [tutorial, setTutorial] = useState('join');
  const [game, setGame] = useState(null);
  const [users, setUsers] = useState([]);
  const [oldUsers, setOldUsers] = useState([]);
  const [shots, setShots] = useState(0);
  const [missedShots, setMissedShots] = useState(0);

  const [music, setMusic] = useState('idle');
  const [playingMusic, setPlayingMusic] = useState(false);

  //Holstering
  const [roundHighScore, setRoundHighScore] = useState([]);
  const [holsterStart, setHolsterStart] = useState(0);
  const [holsteredPlayers, setHolsteredPlayers] = useState([]);
  const [startTime, setStartTime] = useState(getRandomTime());

  useEffect(() => {
    const images = [background, shootImage, holdImage];
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    if (roomCode) {
      set(ref(database, `games/${roomCode}/round/playing`), false);
      setValidRoom(true);
    } else {
      setValidRoom(false);
    }
  }, [roomCode, userUid]);

  useEffect(() => {
    const roundData = ref(database, `games/${roomCode}`);
    onValue(roundData, (snapshot) => {
      const data = snapshot.val();
      setGame(data);
    });
  }, [roomCode]);

  const prepareRound = () => {
    setMusic('idle');
    attemptPlaySound('interaction');
    setHolsterStart(Date.now());
    setGameStage('holster');
    attemptPlaySound('holster');
  };

  const startRound = () => {
    attemptPlaySound('interaction');
    const newStartTime = getRandomTime();
    set(ref(database, `games/${roomCode}/round`), {
      playing: true,
      start: serverTimestamp(),
      delay: newStartTime.delay,
    });
    setStartTime(newStartTime);
    setGameStage('staredown');
    setMusic('staredown');
    setTimeout(() => {
      setGameStage('shootout');
      setMusic('shootout');
      setTimeout(() => {
        attemptPlaySound('gameEnd');
        setGameStage('aftermath');
        setMusic('aftermath');
      }, 2500);
    }, newStartTime.delay);
  };

  useEffect(() => {
    if (game) {
      if (gameStage === 'holster') {
        setShots(0);
        setMissedShots(0);
        const newHolsteredPlayers = [];
        for (const key in game.players) {
          const player = game.players[key];
          if (player.holstered !== false && player.holstered >= holsterStart) {
            newHolsteredPlayers.push({ ...player, id: key });
          }
        }
        if (newHolsteredPlayers.length !== holsteredPlayers.length) {
          setHolsteredPlayers(newHolsteredPlayers);
          if (newHolsteredPlayers.length > holsteredPlayers.length) {
            attemptPlaySound('holstered');
          }
        }
      }
    }
  }, [game, gameStage, holsteredPlayers, holsterStart]);

  useEffect(() => {
    if (game) {
      if (gameStage === 'staredown') {
        let newMissedShots = 0;
        for (const key in game.players) {
          const player = game.players[key];
          if (holsteredPlayers.some((e) => e.id === key)) {
            if (player.missedShot >= startTime.now) {
              newMissedShots++;
            }
          }
        }
        setMissedShots(newMissedShots);
      }
    }
  }, [game, gameStage, holsteredPlayers, startTime]);

  useEffect(() => {
    if (game) {
      if (gameStage === 'shootout') {
        const newRoundHighscore = [];
        const newRoundDnf = [];
        for (const key in game.players) {
          const player = game.players[key];
          if (holsteredPlayers.some((e) => e.id === key)) {
            if (player.shot !== false && player.shot >= startTime.time) {
              player.penalty = false;
              player.penaltyTime = 0;
              if (player.missedShot >= startTime.now) {
                player.penalty = true;
                player.penaltyTime = 250;
              }
              newRoundHighscore.push({ ...player, id: key });
            } else {
              newRoundDnf.push({ ...player, shot: false, id: key });
            }
          }
        }
        newRoundHighscore.sort(
          (a, b) =>
            parseFloat(a.shot + a.penaltyTime) -
            parseFloat(b.shot + b.penaltyTime)
        );
        const newRoundScores = newRoundHighscore.concat(newRoundDnf);
        setShots(newRoundHighscore.length);
        setRoundHighScore(newRoundScores);
      }
    }
  }, [game, gameStage, holsteredPlayers, startTime]);

  useEffect(() => {
    if (shots !== 0) {
      attemptPlaySound('gunshot');
    }
  }, [shots]);

  useEffect(() => {
    if (missedShots !== 0) {
      attemptPlaySound('miss');
    }
  }, [missedShots]);

  useEffect(() => {
    if (game) {
      if (gameStage === 'aftermath') {
        set(ref(database, `games/${roomCode}/round/playing`), false);
      }
    }
  }, [game, roomCode, gameStage]);

  useEffect(() => {
    if (game) {
      const newUsers = [];
      for (const key in game.users) {
        const player = game.users[key];
        newUsers.push({ ...player, id: key });
      }
      setUsers(newUsers);
      if (newUsers.length !== oldUsers.length) {
        setOldUsers(newUsers);
        if (newUsers.length > oldUsers.length) attemptPlaySound('newPlayer');
      }
    }
  }, [game, oldUsers]);

  useEffect(() => {
    if (playingMusic) {
      switch (music) {
        case 'idle':
          musicRef.current.src = idleMusicSrc;
          musicRef.current.volume = 0.1;
          musicRef.current.play();
          break;
        case 'staredown':
          musicRef.current.src = playMusicSrc;
          musicRef.current.volume = 0.5;
          musicRef.current.play();
          break;
        case 'aftermath':
          musicRef.current.src = aftermathMusicSrc;
          musicRef.current.volume = 0.5;
          musicRef.current.play();
          break;
        case 'shootout':
          attemptPlaySound('draw');
          break;
        default:
          console.log(`Unknown music ${music}`);
          break;
      }
    }
  }, [music, playingMusic]);

  useEffect(() => {
    if (playingMusic === true) {
      musicRef.current.play();
    } else {
      musicRef.current.pause();
    }
  }, [playingMusic]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.code === 'Escape') {
        if (gameStage === 'intro') {
          if (tutorial === 'play') {
            setTutorial('join');
          } else if (gameStage !== 'shootout' && gameStage !== 'staredown') {
            setValidRoom(false);
          }
        } else {
          setGameStage('intro');
          setTutorial('join');
        }
      } else if (e.code === 'Enter' || e.code === 'Space') {
        if (gameStage === 'intro') {
          if (tutorial === 'join') {
            setTutorial('play');
          } else {
            prepareRound();
            setPlayingMusic(true);
          }
        } else if (gameStage === 'holster' && holsteredPlayers.length > 0) {
          startRound();
        } else if (gameStage === 'aftermath') {
          prepareRound();
        }
      }
    };

    window.addEventListener('keyup', keyDownHandler);
    return () => {
      window.removeEventListener('keyup', keyDownHandler);
    };
  }, [gameStage, holsteredPlayers, tutorial]);

  const attemptPlaySound = (sound) => {
    if (playingMusic === true) {
      playSound(sound);
    }
  };

  if (validRoom === false) {
    return <Redirect to="/" />;
  }

  return (
    <Main background={background} opacity={1} animate>
      <Header
        toggleMusic={() => {
          setPlayingMusic(!playingMusic);
        }}
        playingMusic={playingMusic}
        goToIntro={() => {
          setGameStage('intro');
          setTutorial('join');
        }}
        room={roomCode}
      />
      <Stage>
        <audio ref={musicRef} src={idleMusicSrc} loop autoPlay={true} />
        {gameStage === 'intro' && (
          <StageIntro
            startGame={() => {
              prepareRound();
              setPlayingMusic(true);
            }}
            room={roomCode}
            tutorial={tutorial}
            setTutorial={(stage) => {
              setPlayingMusic(true);
              setTutorial(stage);
              attemptPlaySound('interaction');
            }}
          />
        )}
        {gameStage === 'holster' && (
          <StageHolster players={holsteredPlayers} startRound={startRound} />
        )}
        {gameStage === 'staredown' && (
          <StagePlay
            stage={'staredown'}
            image={holdImage}
            text={'Hold your fire'}
          />
        )}
        {gameStage === 'shootout' && (
          <StagePlay stage={'shootout'} image={shootImage} text={'Draw!'} />
        )}
        {gameStage === 'aftermath' && (
          <StageAftermath
            highscore={roundHighScore}
            startGame={prepareRound}
            time={startTime.time}
          />
        )}
      </Stage>
      <Footer users={users}>
        {users.length >= 1 && <UserList users={users} />}
      </Footer>
      <Credits />
    </Main>
  );
};

export default Display;
