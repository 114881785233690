import playerTrack1 from '../assets/sound/newPlayer/1.mp3';
import playerTrack2 from '../assets/sound/newPlayer/2.mp3';
import playerTrack3 from '../assets/sound/newPlayer/3.mp3';
import holsterTrack1 from '../assets/sound/holster/1.mp3';
import holsterTrack2 from '../assets/sound/holster/2.mp3';
import holsterTrack3 from '../assets/sound/holster/3.mp3';
import holsterTrack4 from '../assets/sound/holster/4.mp3';
import holsterTrack5 from '../assets/sound/holster/5.mp3';
import gunshotTrack1 from '../assets/sound/gunshot/1.mp3';
import gunshotTrack2 from '../assets/sound/gunshot/2.mp3';
import gunshotTrack3 from '../assets/sound/gunshot/3.mp3';
import gunshotTrack4 from '../assets/sound/gunshot/4.mp3';
import gunshotTrack5 from '../assets/sound/gunshot/5.mp3';
import gunshotTrack6 from '../assets/sound/gunshot/6.mp3';
import drawTrack1 from '../assets/sound/draw/1.mp3';
import drawTrack2 from '../assets/sound/draw/2.mp3';
import drawTrack3 from '../assets/sound/draw/3.mp3';
import interactionTrack1 from '../assets/sound/interaction/1.mp3';
import interactionTrack2 from '../assets/sound/interaction/2.mp3';
import interactionTrack3 from '../assets/sound/interaction/3.mp3';
import interactionTrack4 from '../assets/sound/interaction/4.mp3';
import interactionTrack5 from '../assets/sound/interaction/5.mp3';
import interactionTrack6 from '../assets/sound/interaction/6.mp3';
import interactionTrack7 from '../assets/sound/interaction/7.mp3';
import gameEndTrack1 from '../assets/sound/gameEnd/1.mp3';
import reloadTrack1 from '../assets/sound/reload/1.mp3';
import missTrack1 from '../assets/sound/miss/1.mp3';
import missTrack2 from '../assets/sound/miss/2.mp3';
import missTrack3 from '../assets/sound/miss/3.mp3';

const playSound = (sound) => {
  switch (sound) {
    case 'newPlayer':
      const playerTracks = [playerTrack1, playerTrack2, playerTrack3];
      playTrack(sound, playerTracks);
      break;
    case 'holstered':
      const holsterTracks = [
        holsterTrack1,
        holsterTrack2,
        holsterTrack3,
        holsterTrack4,
        holsterTrack5,
      ];
      playTrack(sound, holsterTracks);
      break;
    case 'draw':
      const drawTracks = [drawTrack1, drawTrack2, drawTrack3];
      playTrack(sound, drawTracks);
      break;
    case 'holster':
      break;
    case 'gunshot':
      const gunshotTracks = [
        gunshotTrack1,
        gunshotTrack2,
        gunshotTrack3,
        gunshotTrack4,
        gunshotTrack5,
        gunshotTrack6,
      ];
      playTrack(sound, gunshotTracks);
      break;
    case 'interaction':
      const interactionTracks = [
        interactionTrack1,
        interactionTrack2,
        interactionTrack3,
        interactionTrack4,
        interactionTrack5,
        interactionTrack6,
        interactionTrack7,
      ];
      playTrack(sound, interactionTracks);
      break;
    case 'miss':
      const missTracks = [missTrack1, missTrack2, missTrack3];
      playTrack(sound, missTracks);
      break;
    case 'gameEnd':
      const gameEndTracks = [gameEndTrack1];
      playTrack(sound, gameEndTracks);
      break;

    case 'reload':
      const reloadTracks = [reloadTrack1];
      playTrack(sound, reloadTracks);
      break;
    default:
      console.log(`Unknown sound ${sound}`);
      break;
  }
};

let prevEvent = '';
let prevTrack = -1;

const playTrack = (event, tracks) => {
  const getRandomTrack = () => {
    return Math.round(Math.random() * Math.max(tracks.length - 1, 0));
  };

  let trackIndex = getRandomTrack();
  if (tracks.length > 1 && event === prevEvent) {
    while (trackIndex === prevTrack) {
      trackIndex = getRandomTrack();
    }
  }

  prevEvent = event;
  prevTrack = trackIndex;
  const track = tracks[trackIndex];
  let audio = new Audio(track);
  audio.play();
};

export default playSound;
