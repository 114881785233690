import React from 'react';
import styles from './footer.module.scss';
import PropTypes from 'prop-types';

const Footer = ({ children }) => {
  return <footer className={styles.footer}>{children}</footer>;
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: null,
};

export default Footer;
