import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { auth } from './utils/firebase';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { SettingsContext } from './contexts/settings-provider';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Join from './pages/join';
import Play from './pages/play';
import Display from './pages/display';
import Test from './pages/test';

function App() {
  const [, dispatch] = useContext(SettingsContext);

  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {})
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }, []);

  useEffect(() => {
    const unsubsrcibe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({ type: 'update_user', payload: user });
      }
    });
    return () => {
      unsubsrcibe();
    };
  }, [dispatch]);

  const gaId = 'G-7SGHSN9RC9';
  ReactGA.initialize(gaId);

  const title = 'Gunslinger Heroes';
  const description = 'Duels of fate';

  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="description" content={description} />
        <meta name="og:type" content={'page'} />
        <meta name="og:image" content={'https://gunslinger.app/some.png'} />
        <meta name="og:url" content={`https://gunslinger.app`} />
      </Helmet>
      <Switch>
        <Route path="/display">
          <Display />
        </Route>
        <Route path="/play">
          <Play />
        </Route>
        <Route path="/create">
          <Join onlyCreate />
        </Route>
        <Route path="/test">
          <Test />
        </Route>
        <Route path="/">
          <Join />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
