import React from 'react';
import styles from './user.module.scss';
import PropTypes from 'prop-types';

const User = ({ room, name }) => {
  return (
    <div className={styles.user}>
      <div>
        Room: <span className={styles.value}>{room}</span>
      </div>
      <div>
        Player: <span className={styles.value}>{name}</span>
      </div>
    </div>
  );
};

User.propTypes = {
  room: PropTypes.string,
  orientation: PropTypes.string,
};

User.defaultProps = {
  room: null,
  name: null,
};

export default User;
