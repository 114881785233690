import React from "react";
import styles from "./form.module.scss";
import PropTypes from "prop-types";

const Form = ({ children }) => {
  return <div className={styles.form}>{children}</div>;
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

Form.defaultProps = {
  children: null,
};

export default Form;
