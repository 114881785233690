import React from 'react';
import styles from './play-header.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PlayHeader = ({ leaveGame }) => {
  return (
    <div className={styles.header}>
      <div className={styles.leave} onClick={leaveGame}>
        <Link to={'/'}>Leave game</Link>
      </div>
    </div>
  );
};

PlayHeader.propTypes = {
  leaveGame: PropTypes.func,
};

PlayHeader.defaultProps = {
  leaveGame: null,
};

export default PlayHeader;
