import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { database } from '../utils/firebase';
import { ref, set, get, onValue, serverTimestamp } from 'firebase/database';
import { SettingsContext } from '../contexts/settings-provider';
import '../styles/disableShake.scss';
import NoSleep from 'nosleep.js';
import playSound from '../utils/playSound';
import Main from '../components/main/main';
import Button from '../components/button/button';
import Form from '../components/form/form';
import Loading from '../components/loading/loading';
import Debug from '../components/debug/debug';
import User from '../components/user/user';
import PlayHeader from '../components/play-header/play-header';
import background from '../assets/backgrounds/play.png';

const Play = () => {
  const [settings] = useContext(SettingsContext);

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [validRoom, setValidRoom] = useState(null);
  const [validUser, setValidUser] = useState(null);
  const [validSession, setValidSession] = useState(null);
  const [sessionError, setSessionError] = useState('');

  const [roomCode] = useState(location.state?.room);
  const [displayName] = useState(location.state?.name);
  const [userUid] = useState(settings.user.uid);

  const [debug] = useState(true);
  const [permission, setPermission] = useState(false);
  const [orientation, setOrientation] = useState({
    alpha: 0,
    beta: 90,
    gamma: 0,
  });

  const [playing, setPlaying] = useState(false);
  const [roundStart, setRoundStart] = useState(0);
  const [holstered, setHolstered] = useState(false);
  const [hasShot, setHasShot] = useState(false);
  const [gunStatus, setGunStatus] = useState('');

  useEffect(() => {
    if (roomCode) {
      const roundData = ref(database, `games/${roomCode}/round`);
      get(roundData)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setValidRoom(true);
          } else {
            setValidRoom(false);
            setSessionError('Room does not exist');
          }
        })
        .catch((error) => {
          console.error(error);
          setValidRoom(false);
        });
    } else {
      setValidRoom(false);
    }
  }, [roomCode]);

  useEffect(() => {
    if (displayName && userUid) {
      setValidUser(true);
    } else {
      setValidUser(false);
    }
  }, [displayName, userUid]);

  useEffect(() => {
    if (validRoom === true && validUser === true) {
      setValidSession(true);
    } else if (validRoom === false || validUser === false) {
      setValidSession(false);
    }
  }, [validRoom, validUser]);

  useEffect(() => {
    if (validSession === true) {
      setLoading(false);
      set(
        ref(database, `games/${roomCode}/users/${userUid}/name`),
        displayName
      );
      set(
        ref(database, `games/${roomCode}/players/${userUid}/name`),
        displayName
      );
    }
  }, [validSession, roomCode, userUid, displayName]);

  // LISTEN FOR ROUND DATA
  useEffect(() => {
    if (validSession === true) {
      const roundData = ref(database, `games/${roomCode}/round`);
      onValue(roundData, (snapshot) => {
        const data = snapshot.val();
        const roundPlaying = data?.playing ? data.playing : false;
        const roundStart = data?.start ? data.start : 0;
        const roundDelay = data?.delay ? data.delay : 0;
        setPlaying(roundPlaying);
        setRoundStart(roundStart + roundDelay);
        if (roundPlaying === true && holstered === true) {
          const timeNow = Date.now();
          const delay = roundStart + roundDelay - timeNow;
          if (delay > 10) {
            setTimeout(() => {
              //navigator.vibrate(200);
            }, delay);
          }
        }
      });
    }
  }, [roomCode, validSession, holstered]);

  // SET PLAYER AS HOLSTERED
  useEffect(() => {
    if (validSession === true) {
      if (holstered === true) {
        const holsterTime = Date.now();
        set(
          ref(database, `games/${roomCode}/players/${userUid}/holstered`),
          holsterTime
        );
      } else {
        set(
          ref(database, `games/${roomCode}/players/${userUid}/holstered`),
          false
        );
      }
    }
  }, [holstered, roomCode, userUid, validSession]);

  // CHECK PHONE ORIENTATION
  useEffect(() => {
    if (validSession === true) {
      const holsteredOffset = {
        low: 30,
        high: 30,
      };
      const shootOffset = {
        low: 15,
        high: 30,
      };
      const axis = orientation.beta;
      //SHOOTING
      if (
        (axis > 0 - shootOffset.low && axis < 0 + shootOffset.high) ||
        (Math.abs(axis) > 180 - shootOffset.low &&
          Math.abs(axis) < 180 + shootOffset.high)
      ) {
        const shootTime = Date.now();

        if (hasShot === false && playing === true && shootTime >= roundStart) {
          set(
            ref(database, `games/${roomCode}/players/${userUid}/shot`),
            serverTimestamp()
          );
          playSound('gunshot');
        } else if (hasShot === false && playing === true) {
          set(
            ref(database, `games/${roomCode}/players/${userUid}/missedShot`),
            serverTimestamp()
          );
        }
        setHasShot(true);
        setGunStatus('shoot');
        setHolstered(false);
      }
      //HOLSTERING
      else if (
        axis > -90 - holsteredOffset.low &&
        axis < -90 + holsteredOffset.high
      ) {
        setGunStatus('holstered');
        setHolstered(true);
        setHasShot(false);
        if (holstered === false) {
          playSound('holstered');
        }
      }
      //NONE
      else {
        setGunStatus('none');
        setHolstered(false);
      }
    }
  }, [
    orientation,
    hasShot,
    holstered,
    playing,
    roundStart,
    userUid,
    roomCode,
    validSession,
  ]);

  useEffect(() => {
    if (validSession === true) {
      set(
        ref(database, `games/${roomCode}/users/${userUid}/permission`),
        permission
      );
    }
  }, [permission, roomCode, userUid, validSession]);

  const handleOrientation = (event) => {
    const newOrientation = {
      alpha: Math.round(event.alpha),
      beta: Math.round(event.beta),
      gamma: Math.round(event.gamma),
    };
    setOrientation(newOrientation);
  };

  const getOrientationPermission = () => {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
        .then((state) => {
          if (state === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation);
            setPermission(true);
            playSound('reload');
          } else {
            setPermission(false);
          }
        })
        .catch(console.error);
    } else {
      window.addEventListener('deviceorientation', handleOrientation);
      setPermission(true);
      playSound('reload');
    }
    window.screen.orientation.lock('portrait-primary');
    var noSleep = new NoSleep();
    noSleep.enable();
  };

  const leaveGame = () => {
    set(ref(database, `games/${roomCode}/users/${userUid}`), null);
    set(ref(database, `games/${roomCode}/players/${userUid}`), null);
  };

  if (validSession === false) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { error: sessionError },
        }}
      />
    );
  }

  if (loading === true) {
    return <Loading message={'Loading room'} />;
  }

  return (
    <Main center background={background} opacity={0.8}>
      <PlayHeader leaveGame={leaveGame} />
      <Form>
        <User room={roomCode} name={displayName} />
        {permission === false && (
          <Button
            onClick={() => {
              getOrientationPermission();
            }}
          >
            Im ready
          </Button>
        )}
      </Form>
      {debug && <Debug status={gunStatus} orientation={orientation.beta} />}
    </Main>
  );
};

export default Play;
