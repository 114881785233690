import React from 'react';
import styles from './stage-holster.module.scss';
import PropTypes from 'prop-types';
import Heading from '../heading/heading';
import Button from '../button/button';
import Container from '../container/container';

const StageHolster = ({ players, startRound }) => {
  const playerList = players.map((player) => {
    return (
      <div key={player.id} className={styles.player}>
        {player.name}
      </div>
    );
  });

  return (
    <Container>
      <Heading>Holstered players:</Heading>
      <div className={styles.playerList}>
        {playerList}
        {players.length === 0 && (
          <div className={styles.noPlayers}>No holstered players</div>
        )}
      </div>
      {players.length > 0 && (
        <Button onClick={startRound}>
          Start round with {players.length} holstered{' '}
          {players.length > 1 ? 'players' : 'player'}
        </Button>
      )}
    </Container>
  );
};

StageHolster.propTypes = {
  players: PropTypes.array,
  startRound: PropTypes.func,
};

StageHolster.defaultProps = {
  players: [],
  startRound: null,
};

export default StageHolster;
