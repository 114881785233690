import React from 'react';
import styles from './stage.module.scss';
import PropTypes from 'prop-types';

const Stage = ({ children }) => {
  return <section className={styles.stage}>{children}</section>;
};

Stage.propTypes = {
  children: PropTypes.node.isRequired,
};

Stage.defaultProps = {
  children: null,
};

export default Stage;
