import React from 'react';
import styles from './main.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Main = ({ center, background, animate, opacity, children }) => {
  return (
    <main
      className={classNames(styles.main, { [styles.center]: center === true })}
    >
      {background && (
        <div
          className={classNames(styles.background, {
            [styles.animate]: animate === true,
          })}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className={styles.overlay} style={{ opacity: 1 - opacity }} />
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </main>
  );
};

Main.propTypes = {
  center: PropTypes.bool,
  background: PropTypes.string,
  opacity: PropTypes.number,
  animate: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Main.defaultProps = {
  center: false,
  background: null,
  opacity: 1,
  animate: false,
  children: null,
};

export default Main;
