import React from 'react';
import styles from './input.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  stretch,
  centered,
  uppercase,
  hidden,
  onChange,
  ...props
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.stretch]: stretch === true,
        [styles.hidden]: hidden === true,
      })}
    >
      <div className={styles.container}>
        <input
          className={classNames(
            styles.input,
            { [styles.centered]: centered === true },
            { [styles.uppercase]: uppercase === true }
          )}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          {...props}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  stretch: PropTypes.bool,
  centered: PropTypes.bool,
  uppercase: PropTypes.bool,
  hidden: PropTypes.bool,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  title: null,
  description: null,
  stretch: false,
  centered: false,
  uppercase: false,
  hidden: false,
  onChange: null,
};

export default Input;
