import React from 'react';
import styles from './debug.module.scss';
import PropTypes from 'prop-types';

const Debug = ({ status, orientation }) => {
  return (
    <div className={styles.debug}>
      <div className={styles.data}>Gun status: {status}</div>

      <div className={styles.data}>Orientation: {orientation}</div>
    </div>
  );
};

Debug.propTypes = {
  status: PropTypes.string,
  orientation: PropTypes.number,
};

Debug.defaultProps = {
  status: null,
  orientation: -1,
};

export default Debug;
