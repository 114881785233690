import React from "react";
import { reducer, initialState } from "./reducer";

export const SettingsContext = React.createContext({
  settings: initialState(),
  dispatch: () => null,
});

export const SettingsProvider = ({ children }) => {
  const [settings, dispatch] = React.useReducer(reducer, initialState());
  return (
    <SettingsContext.Provider value={[settings, dispatch]}>
      {children}
    </SettingsContext.Provider>
  );
};
