import styles from './heading.module.scss';
import { H } from 'react-headings';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Heading = ({ className, children, ...props }) => {
  return (
    <H className={classNames(styles.heading, className)} {...props}>
      {children}
    </H>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
};

Heading.defaultProps = {
  className: null,
};

export default Heading;
