import React from 'react';
import styles from './player-list.module.scss';
import PropTypes from 'prop-types';

const PlayerList = ({ children }) => {
  return <div className={styles.list}>{children}</div>;
};

PlayerList.propTypes = {
  children: PropTypes.node,
};

PlayerList.defaultProps = {
  children: null,
};

export default PlayerList;
