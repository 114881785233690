/* eslint-disable no-case-declarations */
const defaultSettings = {
  user: false,
};

export const reducer = (settings, action) => {
  switch (action.type) {
    case "update_user":
      const updatedUser = {
        ...settings,
        user: action.payload,
      };
      return updatedUser;

    default:
      return settings;
  }
};

export const initialState = () => {
  return defaultSettings;
};
