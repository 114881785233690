import React from 'react';
import styles from './button.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  icon,
  small,
  secondary,
  disabled,
  error,
  children,
  ...props
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.secondary]: secondary === true,
        },
        {
          [styles.small]: small === true,
        }
      )}
      disabled={disabled}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon()}</div>}
      {children}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.func,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  icon: null,
  small: false,
  secondary: false,
  disabled: false,
  error: null,
  children: null,
};

export default Button;
