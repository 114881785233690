import styles from './error.module.scss';
import PropTypes from 'prop-types';

const Error = ({ children }) => {
  return <div className={styles.error}>{children}</div>;
};

Error.propTypes = {
  children: PropTypes.node,
};

Error.defaultProps = {
  children: null,
};

export default Error;
