import React from 'react';
import styles from './stage-aftermath.module.scss';
import PropTypes from 'prop-types';
import Heading from '../heading/heading';
import Button from '../button/button';
import PlayerList from '../player-list/player-list';
import Container from '../container/container';

const StageAftermath = ({ startGame, highscore, time }) => {
  const highscoreList = highscore.map((player) => {
    if (player.shot === false) {
      return (
        <div key={player.id} className={styles.player}>
          <div className={styles.cell}>
            <span className={styles.name}>{player.name}</span>
          </div>{' '}
          <div className={styles.cell}>
            <span className={styles.time}>DNF</span>
          </div>
        </div>
      );
    } else {
      return (
        <div key={player.id} className={styles.player}>
          <div className={styles.background}>
            <svg
              width="324"
              height="97"
              viewBox="0 0 324 97"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M0 47V7.5L139 0L145 12H323.5V53H306.5L323.5 58.5V90.5L7 96.5L0 58.5L15 47H0Z"
                fill="#944743"
              />
            </svg>
          </div>
          <div className={styles.cell}>
            <span className={styles.name}>{player.name} </span>{' '}
          </div>{' '}
          <div className={styles.cell}>
            <span className={styles.time}>{`${player.shot - time}ms`}</span>
            {player.penalty === true && (
              <div
                className={styles.penalty}
              >{` + ${player.penaltyTime}ms`}</div>
            )}
          </div>
        </div>
      );
    }
  });

  return (
    <Container>
      <Heading>Scoreboard:</Heading>
      <PlayerList>{highscoreList}</PlayerList>
      <Button onClick={startGame}>New round</Button>
    </Container>
  );
};

StageAftermath.propTypes = {
  startGame: PropTypes.func,
  highscore: PropTypes.array,
  time: PropTypes.number,
};

StageAftermath.defaultProps = {
  center: null,
  highscore: [],
  time: 0,
};

export default StageAftermath;
