import React from 'react';
import styles from './stage-intro.module.scss';
import PropTypes from 'prop-types';
import Button from '../button/button';
import Heading from '../heading/heading';
import QRCode from 'react-qr-code';
import classNames from 'classnames';

import join1 from '../../assets/tutorial/join_1.svg';
import join2 from '../../assets/tutorial/join_2.svg';
import join3 from '../../assets/tutorial/join_3.svg';

import play1 from '../../assets/tutorial/play_1.png';
import play3 from '../../assets/tutorial/play_3.svg';

const StageIntro = ({ startGame, tutorial, setTutorial, room }) => {
  return (
    <div className={styles.intro}>
      {tutorial === 'join' ? (
        <div className={styles.tutorial}>
          <Heading className={styles.heading}>How to join</Heading>
          <div className={styles.blocks}>
            <TutorialBlock text={'Scan the QR code to join on your phone'}>
              <div className={styles.qr}>
                <QRCode
                  value={`https://gunslinger.app/?${room}`}
                  size={128}
                  bgColor={'#ffffff'}
                  fgColor={'#04131b'}
                  level={'L'}
                  title={'Join game'}
                />
              </div>
            </TutorialBlock>
            <TutorialBlock text={'Select a username and join the game'}>
              <img
                className={styles.fullImage}
                src={join1}
                alt={'Illustration'}
              />
            </TutorialBlock>
            <TutorialBlock text={'Click the “im ready” button on your phone'}>
              <img
                className={styles.fullImage}
                src={join2}
                alt={'Illustration'}
              />
            </TutorialBlock>
            <TutorialBlock text={'Give gyroscope acces (iPhone only)'}>
              <img
                className={styles.fullImage}
                src={join3}
                alt={'Illustration'}
              />
            </TutorialBlock>
          </div>
          <Button
            onClick={() => {
              setTutorial('play');
            }}
          >
            We´re in, lets see how to play
          </Button>
        </div>
      ) : (
        <div className={styles.tutorial}>
          <Heading className={styles.heading}>How to play</Heading>
          <div className={styles.blocks}>
            <TutorialBlock text={'Holster your gun by pointing it downwards'}>
              <img
                src={play1}
                alt={'illustration'}
                className={classNames(
                  styles.smallImage,
                  styles.anim,
                  styles.holsterAnim
                )}
              />
            </TutorialBlock>
            <TutorialBlock
              text={
                'Shoot by tilting your phone forwards from the holstered position'
              }
            >
              <img
                src={play1}
                alt={'illustration'}
                className={classNames(
                  styles.smallImage,
                  styles.anim,
                  styles.shootAnim
                )}
              />
            </TutorialBlock>
            <TutorialBlock
              text={
                'Start the game and follow the instructions showed on screen'
              }
            >
              <img
                src={play3}
                alt={'illustration'}
                className={classNames(styles.smallImage)}
              />
            </TutorialBlock>
          </div>
          <div className={styles.buttons}>
            <Button
              secondary
              onClick={() => {
                setTutorial('join');
              }}
            >
              Back to joining
            </Button>
            <Button onClick={startGame}>Lets play!</Button>
          </div>
        </div>
      )}
    </div>
  );
};

const TutorialBlock = ({ text, children }) => {
  return (
    <div className={styles.step}>
      <div className={styles.text}>{text}</div>
      <div className={styles.illustration}>{children}</div>
    </div>
  );
};

StageIntro.propTypes = {
  startGame: PropTypes.func,
  room: PropTypes.string,
  tutorial: PropTypes.string,
  setTutorial: PropTypes.func,
};

StageIntro.defaultProps = {
  startGame: null,
  room: null,
  tutorial: 'join',
  setTutorial: null,
};

export default StageIntro;
