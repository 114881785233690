import React, { useState, useEffect } from 'react';
import styles from './loading.module.scss';
import PropTypes from 'prop-types';
import background from '../../assets/backgrounds/display.png';
import Container from '../container/container';

const loadingList = [
  'Pouring whiskey',
  'Polishing guns',
  'Clearing the town',
  'Calling the sheriff',
  'Loading the revolvers',
  'Feeding the horses',
  'Optimising sombreros',
  'Adjusting sights',
  'Cleaning revolvers',
  'Knitting ponchos',
  'Wrangling cattle',
  'Brewing moonshine',
];

const Loading = ({ message }) => {
  const [loadingMessage, setLoadingMessage] = useState(loadingList[0]);

  useEffect(() => {
    const getRandomLoadingMessage = () => {
      return loadingList[Math.floor(Math.random() * (loadingList.length - 1))];
    };

    const loadCounter = setInterval(() => {
      let newLoadingMessage = loadingMessage;
      while (newLoadingMessage === loadingMessage) {
        newLoadingMessage = getRandomLoadingMessage();
      }
      setLoadingMessage(newLoadingMessage);
    }, 1500);
    return () => {
      clearInterval(loadCounter);
    };
  }, [loadingMessage]);

  return (
    <div
      className={styles.loading}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Container>
        <div className={styles.message}>{message ? message : 'Loading...'}</div>
        <div className={styles.loadingFlair}>{loadingMessage}</div>
      </Container>
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: null,
};

export default Loading;
