import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Input from '../components/input/input';
import Button from '../components/button/button';
import Error from '../components/error/error';
import Form from '../components/form/form';
import Main from '../components/main/main';
import Loading from '../components/loading/loading';
import Logo from '../components/logo/logo';
import background from '../assets/backgrounds/join.png';
import PropTypes from 'prop-types';

import displayImage from '../assets/backgrounds/display.png';
import playImage from '../assets/backgrounds/play.png';

const Join = ({ onlyCreate }) => {
  const location = useLocation();
  const functions = getFunctions();
  const newRoom = httpsCallable(functions, 'newRoom');

  const [wordsAsCode] = useState(true);
  const [codeLength] = useState(2);

  const [roomKey, setRoomKey] = useState('');
  const [roomKeyHidden, setRoomKeyHidden] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [loading, setLoading] = useState(false);
  const [roomCode, setRoomCode] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error] = useState(location.state?.error);

  useEffect(() => {
    const images = [displayImage, playImage];
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  const createNewRoom = () => {
    setLoading(true);
    newRoom({ useWords: wordsAsCode, length: codeLength }).then((result) => {
      const data = result.data.room;
      if (data === false) {
        setLoading(false);
        alert.log('Could not create new room');
      } else {
        setLoading(false);
        setRoomCode(data);
      }
    });
  };

  useEffect(() => {
    const linkCode = location.search.replace('?', '').toUpperCase();
    if (linkCode.length >= 1) {
      setRoomKey(linkCode);
      setRoomKeyHidden(true);
    }
  }, [location]);

  useEffect(() => {
    if (roomKey.includes(' ') === true) {
      setRoomKey(roomKey.replace(' ', '-'));
    }
  }, [roomKey]);

  useEffect(() => {
    if (roomName !== '' && roomName.length < 16) {
      if (wordsAsCode === true && roomKey.length >= codeLength * 3) {
        setValidForm(true);
      } else if (roomKey.length === codeLength) {
        setValidForm(true);
      } else {
        setValidForm(false);
      }
    } else {
      setValidForm(false);
    }
  }, [roomKey, roomName, wordsAsCode, codeLength]);

  if (roomCode !== false) {
    return (
      <Redirect
        to={{
          pathname: '/display',
          state: { room: roomCode },
        }}
      />
    );
  }

  if (submitted === true) {
    return (
      <Redirect
        to={{
          pathname: '/play',
          state: { room: roomKey.toUpperCase(), name: roomName },
        }}
      />
    );
  }

  if (loading === true) {
    return <Loading message={'Creating new room'} />;
  }

  if (onlyCreate === true) {
    return (
      <Main center background={background} opacity={0.7} animate>
        <Logo use3D />
        <Form>
          <Button
            onClick={() => {
              createNewRoom();
            }}
          >
            Create new room
          </Button>
        </Form>
      </Main>
    );
  }

  return (
    <Main center background={background} opacity={0.7} animate>
      <Logo use3D useAnimated />
      <form
        className={'noDesktop'}
        onSubmit={(e) => {
          e.preventDefault();
          if (validForm === true) {
            setSubmitted(true);
          }
          return false;
        }}
      >
        <Form>
          {error && <Error>{error}</Error>}
          <Input
            hidden={roomKeyHidden}
            uppercase
            placeholder={
              wordsAsCode === true ? 'Room code' : '4 char room code'
            }
            centered
            value={roomKey}
            onChange={setRoomKey}
          />
          <Input
            placeholder={'Display name'}
            centered
            onChange={setRoomName}
            value={roomName}
          />
          <Button
            disabled={!validForm}
            onClick={() => {
              if (validForm === true) {
                setSubmitted(true);
              }
            }}
          >
            Join room
          </Button>
        </Form>
      </form>
      <div className={'noMobile'}>
        <Form>
          <Button
            onClick={() => {
              createNewRoom();
            }}
          >
            Create new room
          </Button>
        </Form>
      </div>
    </Main>
  );
};

Join.propTypes = {
  onlyCreate: PropTypes.bool,
};

Join.defaultProps = {
  onlyCreate: false,
};

export default Join;
