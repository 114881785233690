import React from 'react';
import styles from './header.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import Logo from '../logo/logo';

const Header = ({ goToIntro, toggleMusic, playingMusic, room, children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.menu}>
        <div className={styles.logo} onClick={goToIntro}>
          <Logo />
        </div>
        <div className={styles.room}>
          Join on a phone at <div className={styles.domain}>Gunslinger.app</div>{' '}
          with code: <div className={styles.code}>{room}</div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.audio} onClick={toggleMusic}>
            Audio:
            <div className={styles.icon}>
              {playingMusic ? <MdVolumeUp /> : <MdVolumeOff />}
            </div>
          </div>
          <Link to={'/'}>
            <span className={styles.leave}>Leave room</span>
          </Link>
        </div>
      </div>
      {children}
    </div>
  );
};

Header.propTypes = {
  goToIntro: PropTypes.func,
  toggleMusic: PropTypes.func,
  playingMusic: PropTypes.bool,
  room: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  goToIntro: null,
  toggleMusic: null,
  playingMusic: false,
  room: null,
  children: null,
};

export default Header;
