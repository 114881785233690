import React from 'react';
import styles from './stage-play.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Heading from '../heading/heading';

import Container from '../container/container';

const StagePlay = ({ stage, image, text }) => {
  return (
    <div className={classNames(styles.play)}>
      <div className={classNames(styles.stage, styles[stage])}>
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${image})` }}
        />
        <Container>
          <Heading className={styles.instruction}>{text}</Heading>
        </Container>
      </div>
    </div>
  );
};

StagePlay.propTypes = {
  stage: PropTypes.string,
  holdImage: PropTypes.string,
  shootImage: PropTypes.string,
};

StagePlay.defaultProps = {
  stage: 'staredown',
  holdImage: null,
  shootImage: null,
};

export default StagePlay;
